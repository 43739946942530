<template>
  <section class="sections">
    <template v-for="(s, index) in sections">
      <router-link :class="{ disabled: s.user && !user }" :to="s.route" custom v-slot="{ navigate }" :key="s.name">
        <div v-if="s.user && !user" class="section">
          <i :class="s.icon"></i>
          <span>{{s.name}}</span>
          <i class="fa-solid fa-chevron-right fa-md"></i>
        </div>
        <div v-else class="section" @click="navigate">
          <i :class="s.icon"></i>
          <span>{{s.name}}</span>
          <i class="fa-solid fa-chevron-right fa-md"></i>
        </div>
      </router-link>
      <div v-if="index === 0" class="hr" :key="`${s.name}-hr`">
        <hr />
        <span>{{ user ? 'User Settings' : 'Login to access settings below' }}</span>
        <hr />
      </div>
    </template>
  </section>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data: () => ({
    sections: [
      {
        name: "App Settings",
        icon: "fa-solid fa-cog fa-xl",
        route: "/settings/general",
        user: false,
      },
      {
        name: 'Account',
        route: '/settings/account',
        icon: 'fa-solid fa-user fa-xl',
        user: true
      },
      {
        name: 'Connections',
        route: '/settings/connections',
        icon: 'fa-solid fa-link fa-xl',
        user: true,
      },
      {
        name: 'Notifications',
        route: '/settings/notifications',
        icon: 'fa-solid fa-bell fa-xl',
        user: true,
      },
      {
        name: 'Privacy',
        route: '/settings/privacy',
        icon: 'fa-solid fa-lock fa-xl',
        user: true,
      },
      {
        name: 'Security',
        route: '/settings/security',
        icon: 'fa-solid fa-shield-alt fa-xl',
        user: true,
      },
    ]
  }),
}
</script>